.ClusterList {

}

.ClusterSummary {
  background-color: whitesmoke;
  color: #333;
  padding: 4px 10px;
  font-size: 11px;
}
.ClusterListItem {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
}

.stateIdentifier {
  font-size: 12px;
  color: #333;
}

.drilledDate {
  font-size: 12px;
  color: #777;
}

.link {
  color: #398BFB;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 5px;
}



.nolink {
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 5px;
}

.depthWrapper {
  position: relative;
  height: 5px;
  background-color: whitesmoke;
  margin: 5px 0px;
}

.depthInFeet {
  font-size: 10px;
  color: #777;
}

.depthBar {
  background-color: #549BF9;
  height: 5px;
}