.navbar-brand {
  padding: 5px 15px;
}

.navbar-collapse.in {
  overflow-y: inherit;
}

.navbar {
  z-index: 500;
}

.AppBody {
  z-index: 400;
  position: absolute;
  top: 50px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-top: 1px solid whitesmoke;
}

.recordCount {
  color: black;
  font-size: 12px;
}

.kinertia {
  font-size: 10px;
}

.infoText {
  text-align: right;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media (max-width: 767px) {
  .infoText {
    text-align: left;
  }
}