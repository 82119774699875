.Layout-left {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 30%;
  position: absolute;
  z-index: 101;
}

.Layout-right {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 70%;
  position: absolute;
  overflow-y: scroll;
  z-index: 102;
  background-color: transparent;
  /* opacity: 0.9; */
}



@media (max-width: 960px) {
  .Layout-left {
    position: relative;
    height: 400px;
    width: 100%;
  }
  .Layout-right {
    position: relative;
    width: 100%;
    left: inherit;
  }
}