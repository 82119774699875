.WellMap {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.WellMap-selectClusters {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 100px;
  z-index: 1000;
}

.WellMap-blurb {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 30px;
  padding: 10px;
  z-index: 999;
  background-color: white;
  opacity: 0.7;
}

.WellMap .leaflet-right {
  left: 5px;
  bottom: 5px;
  right: inherit;
}

.WellMap .leaflet-container .leaflet-control-attribution {
  background-color: transparent;
}

@media (max-width: 470px) {
  .WellMap-blurb {
    height: 70px;
  }
}

.WellMap-marker {
  background-color: transparent;
  border: none;
  /* height: 10px; */
  /* width: 10px; */
}

.WellMap-icon {
  background-color: #0462e2;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 1px solid white;
  opacity: 0.7;
}