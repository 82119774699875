.message {
  text-align: center;
  font-weight: 600;
  color: #777;
  margin-top: 20px;
}

.LocationSearch {
  position: absolute;
  top: 10px;
  z-index: 800;
  left: 50px;
  right: 10px;
}

.TextShadow {
  color: white;
  text-shadow: 2px 2px 4px #000000;
}