body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar-brand {
  padding: 5px 15px;
}

.navbar-collapse.in {
  overflow-y: inherit;
}

.navbar {
  z-index: 500;
}

.AppBody {
  z-index: 400;
  position: absolute;
  top: 50px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-top: 1px solid whitesmoke;
}

.recordCount {
  color: black;
  font-size: 12px;
}

.kinertia {
  font-size: 10px;
}

.infoText {
  text-align: right;
  line-height: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}


@media (max-width: 767px) {
  .infoText {
    text-align: left;
  }
}
.Layout-left {
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 30%;
  position: absolute;
  z-index: 101;
}

.Layout-right {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 70%;
  position: absolute;
  overflow-y: scroll;
  z-index: 102;
  background-color: transparent;
  /* opacity: 0.9; */
}



@media (max-width: 960px) {
  .Layout-left {
    position: relative;
    height: 400px;
    width: 100%;
  }
  .Layout-right {
    position: relative;
    width: 100%;
    left: inherit;
  }
}
.ClusterList {

}

.ClusterSummary {
  background-color: whitesmoke;
  color: #333;
  padding: 4px 10px;
  font-size: 11px;
}
.ClusterListItem {
  border-bottom: 1px solid #f2f2f2;
  padding: 10px;
}

.stateIdentifier {
  font-size: 12px;
  color: #333;
}

.drilledDate {
  font-size: 12px;
  color: #777;
}

.link {
  color: #398BFB;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 5px;
}



.nolink {
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 5px;
}

.depthWrapper {
  position: relative;
  height: 5px;
  background-color: whitesmoke;
  margin: 5px 0px;
}

.depthInFeet {
  font-size: 10px;
  color: #777;
}

.depthBar {
  background-color: #549BF9;
  height: 5px;
}
.WellMap {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.WellMap-selectClusters {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 100px;
  z-index: 1000;
}

.WellMap-blurb {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 30px;
  padding: 10px;
  z-index: 999;
  background-color: white;
  opacity: 0.7;
}

.WellMap .leaflet-right {
  left: 5px;
  bottom: 5px;
  right: inherit;
}

.WellMap .leaflet-container .leaflet-control-attribution {
  background-color: transparent;
}

@media (max-width: 470px) {
  .WellMap-blurb {
    height: 70px;
  }
}

.WellMap-marker {
  background-color: transparent;
  border: none;
  /* height: 10px; */
  /* width: 10px; */
}

.WellMap-icon {
  background-color: #0462e2;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border: 1px solid white;
  opacity: 0.7;
}
.message {
  text-align: center;
  font-weight: 600;
  color: #777;
  margin-top: 20px;
}

.LocationSearch {
  position: absolute;
  top: 10px;
  z-index: 800;
  left: 50px;
  right: 10px;
}

.TextShadow {
  color: white;
  text-shadow: 2px 2px 4px #000000;
}
